import { useContext, useState } from 'react'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { setCookie } from 'nookies'
import { useSelector } from 'react-redux'

import { AuthContext } from 'providers/AuthProvider'

//type
import { Lang } from 'types/lang'

// state
import { getUser } from 'state/ducks/user/selectors'
import { StoreState } from 'state/ducks'

//hooks
import { getLangs, getLangByLocale } from 'hooks/common/lang'

// lib
import checkBreakpoint from 'lib/checkBreakpoint'

import styles from './HeaderMenuComponent.module.scss'

const HeaderMenuComponent = () => {
  const { isSigned } = useContext(AuthContext)
  const { t } = useTranslation()

  const { asPath, locale } = useRouter()

  const auth = useSelector((state: StoreState) => getUser(state))

  const [isShowLang, setIsShowLang] = useState<boolean>(false)

  const { isMobile } = checkBreakpoint()

  const langs: Lang[] = getLangs()

  return (
    <ul className={styles.menu}>
      {!isMobile && (
        <li>
          <Link href="https://join-cloak.ecbo.io/owner">
            <a className={styles.ownerLink}>
              <img src="/owner_icon_blue.svg" alt={t('COMMON.SIDE_MENU.PARTNER')} />
              {t('COMMON.HEADER.PARTNER.REGISTER')}
            </a>
          </Link>
        </li>
      )}
      {!isSigned ? (
        <>
          <li>
            <Link href="/users/sign_in">
              <a className={styles.menuButton}>{t('COMMON.HEADER.LOGIN')}</a>
            </Link>
          </li>
          <li>
            <Link href="/users/sign_up">
              <a className={`${styles.menuButton} ${styles.menuButtonRegister}`}>
                {t('COMMON.HEADER.REGISTER')}
              </a>
            </Link>
          </li>
        </>
      ) : (
        <li>
          <Link href={`/users/${auth.user_id}`}>
            <a className={styles.authLink}>
              <img
                className={styles.authImage}
                src={auth.user_image_url != '' ? auth.user_image_url : '/user_no_image.webp'}
                alt=""
              />
            </a>
          </Link>
        </li>
      )}
      <li className={styles.switchLang}>
        <dl className={styles.switchLangBlock}>
          <dt className={styles.switchLangButton} onClick={() => setIsShowLang(!isShowLang)}>
            <img
              className={styles.switchLangButtonIcon}
              src="/language_icon.svg"
              alt={t('META.LANGUAGE_ICON')}
              height={20}
              width={20}
            />
            <strong className={styles.switchLangButtonText}>{getLangByLocale(locale).name}</strong>
            <img
              src="/triangle_bottom_icon.svg"
              alt={t('META.LANGUAGE_ICON')}
              width={12}
              height={8}
            />
          </dt>
          {isShowLang && (
            <dd className={styles.switchLangBox}>
              <ul className={styles.switchLangList}>
                {langs.map((lang: Lang, index: number) => (
                  <li
                    className={`${styles.switchLangListItem} ${
                      lang.slug === locale && styles.switchLangListItemSelected
                    }`}
                    key={index}
                  >
                    <Link href={asPath} locale={lang.slug} passHref>
                      <a className={styles.switchLangLink}>{lang.name}</a>
                    </Link>
                  </li>
                ))}
              </ul>
            </dd>
          )}
        </dl>
      </li>
    </ul>
  )
}

export default HeaderMenuComponent
