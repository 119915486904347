import { FC } from 'react'
import styles from './AccordionIconComponent.module.scss'

interface Props {
  isOpenAccordion: boolean
  position?: string
  accordionStyle?: string
}

const AccordionIconComponent: FC<Props> = ({
  isOpenAccordion,
  accordionStyle,
}: Props): JSX.Element => {
  return (
    <div
      className={`${styles.accordionIcon} 
      ${isOpenAccordion ? styles.open : styles.close} 
      ${accordionStyle}`}
    />
  )
}

export default AccordionIconComponent
